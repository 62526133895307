import dateFormat from "dateformat";
import $ from "jquery";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin"
import simpleAxios from "../../../../_helpers/axios";
import Automotive from "../AssetDetailManualComponent/Automotive"
import Marine from "../AssetDetailManualComponent/Marine"
import RV from "../AssetDetailManualComponent/RV"
import PowerSport from "../AssetDetailManualComponent/PowerSport"
import Trailer from "../AssetDetailManualComponent/Trailer"
import Construction from "../AssetDetailManualComponent/Construction"
import { stubFalse } from "lodash";
import { API_URL } from "../../../../constant";
import { toastr } from "react-redux-toastr";
import { history } from "../../../../_helpers";
import { scrollToValidationError } from "./BuyerApplicationDetail";
import { WithSubmit } from "./with-submit";

const validateForm = (obj_validating_keys) => {
  let is_valid = true
  Object.values(obj_validating_keys).forEach((value) => {
    if (value === false) {
      is_valid = false
    }
  });
  return is_valid;
};

class AssetDetail extends WithSubmit {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      screenName: "assets-detail",
      assetIndex: "",
      model: "",
    };
    $("#assets-detail").removeClass("tabDeactive");
  }

  extractDigits = (string = '') => {
    if (string.length > 0) {
      return string.replace(/\D/g, '');
    } else {
      return '';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updateAssetsDetail !== this.props.updateAssetsDetail &&
      this.props.updateAssetsDetail !== undefined
    ) {
      this.props.onClickChangeStep(3, 4);
      // this.props.history.push(`${this.props.url}/verify-identity`);
      this.props.history.push(`${this.props.url}/trade-in`);
    }
  }

  onBack = (para) => {
    if (para === "assets-detail") {
      $("#assets-detail").removeClass("tabDeactive");
      $("#assets-detail").addClass("active");
      $("#assets-detail").addClass("show");
    }

    this.setState({
      ...this.state,
      screenName: para,
    });
  };

  handleSubmit = (event) => {
    scrollToValidationError();

    event.preventDefault();

    this.props.validateOnChangeAssetDetail('vehicleType', this.props.vehicleType)
    this.props.validateOnChangeAssetDetail('sub_type_of_vehicle', this.props.sub_type_of_vehicle)

    if (validateForm(this.props.validation_asset_detail_form)) {

      const payload = {
        user: window.localStorage.getItem('userId'),
        vehicle_type: this.props.vehicleType,
        sub_type_of_vehicle: this.props.sub_type_of_vehicle,
        monthly_budget: this.props.monthly_budget,
        trade_in: this.props.tradeIn,
        vehicle: {
          type_of_vehicle: this.props.vehicleType,
          trade_in: this.props.tradeIn,
          sub_type_of_vehicle: this.props.sub_type_of_vehicle,
          body_type: this.props.body_type,
          year: this.props.year || "",
          make: this.props.make || "",
          trim: this.props.trim || "",
          model: this.props.model || "",
          kilometer: this.props.kilometer,
          vin: this.props.vin || "",
          price: this.props.price || "",
          condition: this.props.condition || "",
          stock_id: this.props.stockNumber || "",
          engine: this.props.engine || "",
          is_updated: false
        },
        form_identity: 3,
        is_just_validation: true
      }

      const createPayload = {

        user: window.localStorage.getItem('userId'),
        salutation: this.props.salutation,
        first_name: this.props.applicantFirstName,
        last_name: this.props.applicantLastName,
        date_of_birth: dateFormat(this.props.applicantDateOfBirth, "yyyy-mm-dd"),
        phone_number: this.extractDigits(this.props.applicantTelephone),
        marital_status: this.props.applicantMaritalStatus,
        email: this.props.applicantEmail,
        address: this.props.applicantStreetAddress,
        sin: this.props.applicantSin,
        city: this.props.applicantCity,
        province: this.props.applicantProvince,
        postal_code: this.props.applicantPostalCode,
        country: this.props.applicantCountry,
        years_at_current_address: this.props.duration_address_yr,
        do_you_own_or_rent: this.props.status,
        amount: this.props.mortgage_amount,
        gender: this.props.gender,
        previous_address_detail: {
          city: this.props.previousCity,
          country: this.props.previousCountry,
          duration_address_mn: this.props.durationAtPreviousAddressMonth,
          duration_address_yr: this.props.durationAtPreviousAddress,
          mortgage_amount: this.props.previousMortgageAmount,
          postal_code: this.props.previousPostalCode,
          province: this.props.previousProvince,
          status: this.props.previousStatus,
          street_address: this.props.applicantPreviousStreetAddress,
        },

        employement_status: this.props.employmentStatus,
        type_of_employment: this.props.typeOfEmployment,
        employer_name: this.props.employerName,
        occupation: this.props.occupation,
        employment_since: dateFormat(this.props.employmentSince, "yyyy-mm-dd"),
        monthly_income: (this.props.monthlyGrossIncome === undefined) ? '' : this.props.monthlyGrossIncome,
        employer_address: this.props.employerStreetAddress,
        employer_city: this.props.employerCity,
        employer_email: this.props.employerEmail,
        employer_telephone: this.extractDigits(this.props.employerPhone),
        employer_province: this.props.employerProvince,
        other_income: {
          income_amount: this.props.income_amount,
          income_type: this.props.income_type,
          income_frequency: this.props.income_frequency,
        },

        vehicle_type: this.props.vehicleType,
        sub_type_of_vehicle: this.props.sub_type_of_vehicle,
        monthly_budget: this.props.monthly_budget,
        trade_in: this.props.tradeIn.length > 0 ? true : false,
        vehicle: {
          type_of_vehicle: this.props.vehicleType,
          trade_in: this.props.tradeIn.length > 0 ? true : false,
          sub_type_of_vehicle: this.props.sub_type_of_vehicle,
          body_type: this.props.body_type,
          year: this.props.year || "",
          make: this.props.make || "",
          model: this.props.model || "",
          trim: this.props.trim || "",
          kilometer: this.props.kilometer || "",
          vin: this.props.vin || "",
          price: this.props.price,
          condition: this.props.condition || "",
          stock_id: this.props.stockNumber || "",
          engine: this.props.engine || "",
          is_updated: false
        },
        is_just_validation: false,
        application_type: 3
      }
      this.disableSubmit();
      if (this.props.operation_mode === 'create') {
        simpleAxios.post(`manual-application/`, payload).then(res => {
          if (res.data.errors.length === 0) {
            simpleAxios.post(`manual-application/`, createPayload).then(res => {
              toastr.success("Success", "Application created");
              history.push('/dealer-admin/application/general');
            });
          } else {
            scrollToValidationError();
          }
        }).finally(() => this.enableSubmit())
      }
      else if (this.props.operation_mode === 'edit') {
        const applicationId = this.props.match.params.id;
        payload.is_just_validation = true;
        payload.vehicle.is_updated = true;
        simpleAxios.put(`manual-application/${applicationId}/`, payload).then(res => {
          toastr.success("Success", "Details successfully updated");
        }).finally(() => this.enableSubmit())
      }
    }
  }

  render() {

    let isDisabled = false;
    if (!this.state.submitButtonVisible) {
      isDisabled = true;
    }

    const assetId =
      this.props.assetsDetails && (this.props.assetsDetails || []).length > 0
        ? this.props.assetsDetails[0].id
        : "";
    const { Option } = components;

    const renderScrollbar = (props) => {
      return (
        <div style={{ height: 260 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderScrollbarSub = (props) => {
      return (
        <div style={{ height: 150 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderScrollbarCondition = (props) => {
      return (
        <div style={{ height: 100 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderOption = (props) => {
      return (
        <Option {...props}>
          <div>{props.data.label}</div>
        </Option>
      );
    };
    const renderOptionSubType = (props) => {
      return (
        <Option {...props}>
          <div>{props.data.label}</div>
        </Option>
      );
    };
    let first = this.props.applicantFirstName
      ? this.props.applicantFirstName.split(" ")[0]
      : "";
    let last = this.props.applicantLastName
      ? this.props.applicantLastName.split(" ")[0]
      : "";
    first = first ? first.charAt(0).toUpperCase() : "";
    last = last ? last.charAt(0).toUpperCase() : "";



    return (
      <React.Fragment>
        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />
            <ul
              className="nav nav-tabs-2 singleItem"
              id="formAppTabs"
              role="tablist"
            >
              <li
                className="nav-item"
                onClick={() => this.changeScreen("assets-detail")}
              >
                <a
                  className={
                    this.state.screenName === "assets-detail"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="assets-detail-tab"
                  data-toggle="tab"
                  href="#assets-detail"
                  role="tab"
                  aria-controls="assets-detail"
                  aria-selected={
                    this.state.screenName === "assets-detail" ? "true" : "false"
                  }
                >
                  {" "}
                  <span className="tabs-text assets-detail">
                    {" "}
                    Asset Detail{" "}
                  </span>{" "}
                </a>
              </li>
            </ul>
            <div className="tab-content" id="formAppTabsContent">





              <div
                className="tab-pane fade show active clearfix"
                id="assets-detail"
                role="tabpanel"
                aria-labelledby="assets-detail-tab"
              >
                {this.state.screenName === "assets-detail" ? (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      <h1> Asset Detail </h1>
                    </div>

                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">


                        <div className="form-field-col">
                          <label> Type of vehicle<em>*</em></label>
                          <Select
                            placeholder="Search Vehicle"
                            id="selectedVehicle"
                            name="vehicleType"
                            value={this.props.selectedVehicle}
                            onChange={(e) => {
                              this.props.changeSelect(
                                e,
                                "vehicleType",
                                "selectedVehicle"
                              );
                            }}
                            options={this.props.vehicleOptions}
                            isSearchable
                            isClearable
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbar,
                            }}
                            captureMenuScroll={false}
                          />
                          {
                            <span className='text-danger'>{this.props.errors_asset_detail_form.vehicleType}</span>
                          }
                        </div>

                        <div id={this.props.trigger_number} className="form-field-col">
                          <label>Subtype of vehicle<em>*</em></label>
                          <Select
                            placeholder={"Subtype of vehicle"}
                            id="selectedSubTypeOfVehicle"
                            name="sub_type_of_vehicle"
                            value={this.props.selectedSubTypeOfVehicle}
                            onChange={(e) => {
                              this.props.changeSelect(
                                e,
                                "sub_type_of_vehicle",
                                "selectedSubTypeOfVehicle"
                              );
                            }}
                            options={this.props.optionsSubTypeOfVehicle}
                            isSearchable
                            isClearable
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOptionSubType,
                              MenuList: renderScrollbarSub,
                            }}
                            captureMenuScroll={false}
                          />
                          {

                            <span className='text-danger'>{this.props.errors_asset_detail_form.sub_type_of_vehicle}</span>
                          }
                        </div>
                      </div>

                      {["automotive"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (
                        <Automotive
                          conditions={this.props.conditions}
                          tradeIns={this.props.tradeIns}
                          handleOnBlurStock={this.props.handleOnBlurStock}
                          changeSelect={this.props.changeSelect}
                          handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                          {...this.props}
                        />
                      ) : null}


                      {["marine"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (
                        <Marine
                          conditions={this.props.conditions}
                          tradeIns={this.props.tradeIns}
                          handleOnBlurStock={this.props.handleOnBlurStock}
                          changeSelect={this.props.changeSelect}
                          handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                          {...this.props}
                        />
                      ) : null}

                      {["rv"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (
                        <RV
                          conditions={this.props.conditions}
                          tradeIns={this.props.tradeIns}
                          handleOnBlurStock={this.props.handleOnBlurStock}
                          changeSelect={this.props.changeSelect}
                          handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                          {...this.props}
                        />
                      ) : null}

                      {["powersport"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (
                        <PowerSport
                          conditions={this.props.conditions}
                          tradeIns={this.props.tradeIns}
                          handleOnBlurStock={this.props.handleOnBlurStock}
                          changeSelect={this.props.changeSelect}
                          handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                          {...this.props}
                        />
                      ) : null}

                      {["trailer", "lawn tractor"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (
                        <Trailer
                          conditions={this.props.conditions}
                          tradeIns={this.props.tradeIns}
                          handleOnBlurStock={this.props.handleOnBlurStock}
                          changeSelect={this.props.changeSelect}
                          handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                          {...this.props}
                        />
                      ) : null}

                      {["construction"].includes(
                        this.props.selectedVehicle?.label?.toLowerCase()
                      ) ? (<Construction
                        conditions={this.props.conditions}
                        tradeIns={this.props.tradeIns}
                        handleOnBlurStock={this.props.handleOnBlurStock}
                        changeSelect={this.props.changeSelect}
                        handleOnChangeAssetDetail={this.props.handleOnChangeAssetDetail}
                        {...this.props}
                      />) : null}

                    </div>



                    <div className="footer-btns-holder clearfix">
                      {this.props.coApplicant === true ? (
                        <Link to={`${this.props.url}/co-applicant`}>
                          {" "}
                          <button
                            className="btn btn-primary float-left"
                            onClick={() => this.props.onClickChangeStep(3, 2)}
                          >
                            {" "}
                            Back{" "}
                          </button>
                        </Link>
                      ) : (
                        <Link to={`${this.props.url}/applicant-detail`}>
                          {" "}
                          <button
                            className="btn btn-primary float-left"
                            onClick={() => this.props.onClickChangeStep(3, 1)}
                          >
                            {" "}
                            Back{" "}
                          </button>
                        </Link>
                      )}

                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          {" "}
                          <i
                            class="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary float-right active"
                          onClick={(event) => { this.handleSubmit(event) }}
                          {...(isDisabled ? { disabled: 'disabled' } : null)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>


            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AssetDetail;
