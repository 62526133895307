import React, { useState } from 'react';
import { API_URL } from '../../../../constant';
const FileUploader = ({ applicationId, setIsLoading, setActionWhileLoading, onFileUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setErrorMessage('');
      handleSubmit(selectedFile);
    } else {
      setFile(null);
      setErrorMessage('Only JPG, JPEG, BMP, and PDF files are allowed.');
    }
  };

  const handleSubmit = (selectedFile) => {
    if (selectedFile) {
      setIsLoading(true);
      setActionWhileLoading('Uploading...');

      var formdata = new FormData();
      formdata.append('buyer_application', applicationId);
      formdata.append('receipt', selectedFile, selectedFile.name);
      formdata.append('receipt_name', '');
      formdata.append('receipt_file_type', '');
      formdata.append('customer_purchase_receipt', false);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${API_URL}/retail-applications/retail-receipt/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          // Handle success
          onFileUploadSuccess();
        })
        .catch(error => {
          // Handle error
          console.error('Error uploading file:', error);
        })
        .finally(() => {
          setIsLoading(false);
          setActionWhileLoading('');
        });
    }
  };

  return (
    <>

        <div className="upload-doc-box">
          <span className="icon-holder"></span>
          <div className="text-box">
            <div className="uploadReceiptText">
              <strong className="title">Upload Purchase Receipt<em>*</em></strong>
              <p>Jpg, jpeg, bmp or pdf format only</p>
              {errorMessage && <p style={{ color: 'red !important' }}>{errorMessage}</p>}
            </div>

            <button className="upload-btn">
              {" "}
              <input
                type="file"
                className="custom-file-input"
                onChange={handleFileChange}
              />
              <span className="text"> Upload </span>{" "}
            </button>
          </div>
        </div>

    </>

  );
};

export default FileUploader;