/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_pending_active_application, get_pending_active_application_paging
} from "../../../../../actions/admin/applicationActions";
import useDebounce from "../../../../../_hooks/useDebounac";
import ApplicationTableTopHeader from "../../../TableComponents/ApplicationTableTopHeader";
import PageLengthComponent from "../../../TableComponents/PageLengthComponent";
import Pagination from "../../../TableComponents/Pagination";
import TableFilter from "./TableComponent/TableFilter";
import TableHead from "./TableComponent/TableHead";
import TableRow from "./TableComponent/TableRow";
import PushToFrontlineModal from '../modal/PushToFrontlineModal';
import axios from 'axios';
import { API_URL } from '../../../../../../src/constant'
import LoadingOverlay from 'react-loading-overlay';

const ManagedApplication = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isApplicationPushed, setIsApplicationPushed] = useState(false)
  const [applicationId, setApplicationId] = useState(-1);

  /**
    * set loading and action while loading
    */
  const [isLoading, setIsLoading] = useState(false);
  const [actionWhileLoading, setActionWhileLoading] = useState("Analyzing your request. Please wait.");


  const { pages_urls, application_data } = useSelector(({ adminReducer }) => {
    return {
      pages_urls: adminReducer.adminAccounts.applicationReducer.pages,
      application_data:
        adminReducer.adminAccounts.applicationReducer.application_data,
    };
  });

  const handlePushToFrontline = (data) => {

    /***
     * Submit application to frontline through api
     * api url: `${API_URL}/push-appl-to-frontline/`
     * method: post
     * body {"id" : applicationId}
     * on response show errors in below modal 
     * 
     * Show errors / status (approve/Decline) in message.
     */

    setIsLoading(true);

    let applicationId = data.id;
    setApplicationId(applicationId);

    axios.post(`${API_URL}/frontline_application/`, { id: applicationId }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 201) {
          return response.data.data;
        } else {
          throw new Error('Please contact the administrator.');
        }
      })
      .then(data => {
        setIsApplicationPushed(true);

        const convertedData = {};
        Object.keys(data).forEach(key => {
          convertedData[key] = [data[key]];
        });

        setModalMessage(convertedData);
        setIsLoading(false);
        setIsModalOpen(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          // Handle 400 Bad Request errors
          setModalMessage(error.response.data.errors);
          setIsLoading(false);
          setIsModalOpen(true);
        } else {

          // Set general error message
          const generalError = {
            errors: {
              general: ['Please contact the administrator.']
            }
          };
          setModalMessage(generalError.errors);
          setIsLoading(false);
          setIsModalOpen(true);
        }
      });
  };

  const [searchParams, setSearchParams] = useState({
    application_view: "managed",
    sort_by: "newest_first",
    p_size: 20,
    page: 1,
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [changePage, setChangePage] = useState(false);
  const [changeSelect, setChangeSelect] = useState(false);
  const componentRef = React.useRef(null);
  const debouncedSearchTerm = useDebounce(search, 500);
  /** Pagination Change */
  useEffect(() => {
    setSearchParams({ ...searchParams, page: page });
    const pageUrl =
      pages_urls.filter((item) => item.page_no === page)?.[0]?.url || "";
    pageUrl && dispatch(get_pending_active_application_paging(pageUrl, null));
  }, [changePage]);
  /** Page Length Change */
  useEffect(() => {
    setSearchParams({ ...searchParams, page: 1, search: search });
    setPage(1);
    dispatch(
      get_pending_active_application({
        ...searchParams,
        page: 1,
        search: search,
      })
    );
  }, [searchParams.p_size, changeSelect, debouncedSearchTerm]);

  const resetStatesOnModalClose = () => {
    setIsModalOpen(false);
    setChangePage(!changePage)
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={actionWhileLoading}
    >
      <div className="Altable-Container lower">
        <ApplicationTableTopHeader />
        <div className="p-2">
          <TableFilter
            search={search}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            setChangeSelect={setChangeSelect}
            changeSelect={changeSelect}
            setSearch={setSearch}
          />
        </div>
        <div className="dealer-dtable table-responsive">
          <table
            style={{ width: "100%" }}
            className="table-striped table-hover"
            ref={componentRef}
          >
            <TableHead />
            <tbody>
              {(application_data || []).map((item) => {
                return <TableRow item={item} handlePushToFrontline={handlePushToFrontline} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && (
        <PushToFrontlineModal
          isOpen={isModalOpen}
          onClose={resetStatesOnModalClose}
          applicationId={applicationId}
          modalMessage={modalMessage}
          isApplicationPushed={isApplicationPushed}
        />
      )}
      <div className="row m-0 mt-2 rounded" style={{ background: "#fff" }}>
        <PageLengthComponent
          searchParams={searchParams}
          setpage={setPage}
          setSearchParams={setSearchParams}
        />

        <Pagination
          pages_urls={pages_urls}
          page={page}
          setPage={setPage}
          setChangePage={setChangePage}
          changePage={changePage}
        />
      </div>
    </LoadingOverlay>
  );
};
export default ManagedApplication;
