let Live_Url = "https://18.219.178.49:8000";

let Flinks_Url =
  "https://financethat-iframe.private.fin.ag/?demo=true&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true";
let Re_Captcha_key = "6LeNWvUZAAAAADMJcWNXRULmvGK3RW6ZUPqt6K-4";
if (process.env.REACT_APP_ENV === "development") {
  Live_Url = "https://dev-api.financethat.ca";
}
if (process.env.REACT_APP_ENV === "staging") {
  Live_Url = "https://18.219.178.49:8000";
}
if (process.env.REACT_APP_ENV === "stage") {
  Live_Url = "https://stage-api.financethat.ca";
}
if (process.env.REACT_APP_ENV === "production") {
  Live_Url = "https://api.financethat.ca";
  Flinks_Url =
    "https://financethat-iframe.private.fin.ag/?redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true";
  Re_Captcha_key = process.env.REACT_APP_RE_CAPTCHA_KEY;
}
if (process.env.REACT_APP_ENV === "newproduction") {
  Live_Url = "https://prod-api.financethat.ca";
  Flinks_Url =
    "https://financethat-iframe.private.fin.ag/?redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true";
  Re_Captcha_key = process.env.REACT_APP_RE_CAPTCHA_KEY;
}

export const API_URL = "https://api.financethat.ca";
export const V1API_FINANCETHAT="https://v1api.financethat.ca";
export const IMAGE_PATH = "http://127.0.0.1/";
export const IMAGE_URL = "https://images.financethat.ca";
export const CLIENT_URL = "https://www.financethat.ca";
export const Flinks = Flinks_Url;
export const Captcha_key = Re_Captcha_key;

export const DEALER_BUTTON_APPLICATION_URL = "https://www.financethat.ca";

export const RETAIL_PROGRAM_APP_URL="https://financethat.ca/retail"

const gaIds = {
	landing_page: "GTM-NJ6G6FB",
	applynow_page: "G-R85EV0W64F"
}

export { gaIds };

