import axios from "axios";
import { API_URL } from "../../../../constant";

export const fetchRetailProgramApplication = (id) => {
  const url = `${API_URL}/retail-applications/application/${id}/personal-and-employment-detail`;
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json; charset=utf8" },
    url,
  };
  return axios(options);
};
