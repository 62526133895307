import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../src/constant'
import PushToFrontlineModal from './modals/PushToFrontlineModal';
import { history } from '../../../_helpers';
import axios from 'axios';

/** Add Loader */
import LoadingOverlay from 'react-loading-overlay';

function ApplynowApplications() {

  const [rowData, setRowData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isApplicationPushed, setIsApplicationPushed] = useState(false)
  const [applicationId, setApplicationId] = useState(-1);
  const [gridApi, setGridApi] = useState(null);
  const [filterQuery, setFilterQuery] = useState('');
  const [currentPage, setCurrentPage] = useState('0');

  /**
   * set loading and action while loading
   */
  const [isLoading, setIsLoading] = useState(false);
  const [actionWhileLoading, setActionWhileLoading] = useState("We are analysing data. Please wait.");

  const handlePushToFrontline = (data, currentPage) => {

    /***
     * Submit application to frontline through api
     * api url: `${API_URL}/push-appl-to-frontline/`
     * method: post
     * body {"id" : applicationId}
     * on response show errors in below modal 
     * 
     * Show errors / status (approve/Decline) in message.
     */

    setIsLoading(true);

    setCurrentPage(currentPage)

    let applicationId = data.id;

    setApplicationId(applicationId);

    axios.post(`${API_URL}/push-appl-to-frontline/`, { id: applicationId }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 201) {
          return response.data.data;
        } else {
          throw new Error('Please contact the administrator.');
        }
        
      })
      .then(data => {
        setIsApplicationPushed(true);

        const convertedData = {};
        Object.keys(data).forEach(key => {
          convertedData[key] = [data[key]];
        });

        setModalMessage(convertedData);
        setIsLoading(false);
        setIsModalOpen(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          // Handle 400 Bad Request errors
          setModalMessage(error.response.data.errors);
          setIsLoading(false);
          setIsModalOpen(true);
        } else {

          // Set general error message
          const generalError = {
            errors: {
              general: ['Please contact the administrator.']
            }
          };
          setModalMessage(generalError.errors);
          setIsLoading(false);
          setIsModalOpen(true);
        }
      });
  };

  const columns = [
    { headerName: 'ID', field: 'id' },
    {
      headerName: 'Applicant Name',
      field: 'fullname',
      valueGetter: params => {
        const firstName = params.data?.firstname ?? '';
        const lastName = params.data?.lastname ?? '';
        return `${firstName} ${lastName}`;
      }
    },
    { headerName: 'Status By Frontline', field: 'front_line_status' },
    { headerName: 'Vehicle Type', field: 'vehicle' },
    { headerName: 'Employment Status', field: 'employment_status' },
    {
      headerName: 'Created',
      field: 'created_at',
      valueGetter: params => {
        // Check if created_at is null
        if (params.data?.created_at === null) {
          return ''; // Return an empty string if null
        }
        // Parse the ISO 8601 date string into a JavaScript Date object
        const createdAt = new Date(params.data?.created_at);
        // Check if createdAt is a valid date
        if (isNaN(createdAt.getTime())) {
          // If not a valid date, return an empty string
          return '';
        } else {
          // If it's a valid date, format it using toLocaleDateString()
          return createdAt.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          });
        }
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        const editUrl = `/admin/applynow/applications/${params.data?.id}/edit`;
        const deleteItem = (id) => {
          setIsLoading(true);
          let pageToBeReserved = params.api.paginationGetCurrentPage();
          localStorage.setItem('pageToBeReserved', pageToBeReserved);
          if (window.confirm("Are you sure you want to delete this item?")) {
            axios.delete(`${API_URL}/applynow/applications/${id}`)
              .then(response => {
                if (gridApi) {
                  fetchRows();
                  /**
                   * Logic should be here to mantain state
                   */
                  let pageToBeReserved = localStorage.getItem('pageToBeReserved');
                  setTimeout(() => {
                    params.api.paginationGoToPage(Number(pageToBeReserved));
                    localStorage.removeItem('pageToBeReserved')
                  }, 1000);
                }
              })
              .catch(error => {
                console.error("Error deleting item:", error);
              })
              .finally(() => {
                setIsLoading(false);
              })
          } else {
            setIsLoading(false);
          }
        };
        return (
          <>
            <button className='btnBlank'>
              <Link className='detaillinkbtn' onClick={() => {
                let pageToBeReserved = params.api.paginationGetCurrentPage();
                localStorage.setItem('pageToBeReserved', pageToBeReserved);
                history.push(editUrl)
              }
              }>
                <img src="/assets/image/icon-edit.svg" alt="" />
              </Link>
            </button>
            {!params.data?.is_pushed_frontline ? (

              <Link className='detaillinkbtn pushFrontBtn' onClick={() => handlePushToFrontline(
                params.data,
                params.api.paginationGetCurrentPage()
              )}>
                Push to frontline
              </Link>
            ) : <Link className="detaillinkbtn retailProgram-PushLine disabled">Pushed to Frontline</Link>
            }
            <button className='btnBlank' onClick={() => {
              deleteItem(params.data?.id)

            }}>
              <img src="/assets/image/sprite-icon/icon-delete.svg" alt="" />
            </button>
          </>
        );
      },
    }
  ];

  const [columnDefs, setColumnDefs] = useState(columns);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const fetchRows = () => {
    if (!gridApi) {
      return;
    }
    const dataSource = {
      getRows: (params) => {
        const page = params.endRow / 10;
        let url = `${API_URL}/applynow/applications/?p_size=10&page=${page}`;
        if (filterQuery != null && filterQuery != '') {
          url = `${API_URL}/applynow/applications/?p_size=10&page=${page}&search=${filterQuery}`;
          localStorage.setItem('filterToBeReserved', filterQuery);
        }
        fetch(url)
          .then(resp => resp.json())
          .then(res => {
            params.successCallback(res.data, res.pagination.count);
          }).catch(err => {
            params.successCallback([], 0);
          });
      }
    };
    gridApi.setDatasource(dataSource);
  }

  useEffect(() => {
    fetchRows();
  }, [columnDefs])

  useEffect(() => {
    if (gridApi) {
      setColumnDefs(columns)
    }
  }, [gridApi, filterQuery]);


  const onFirstDataRendered = event => {
    const { api } = event

    let pageToBeReserved = localStorage.getItem('pageToBeReserved');
    if (api.paginationGetCurrentPage() == 0 && typeof pageToBeReserved != "undefined") {
      let search = localStorage.getItem('filterToBeReserved');
      document.querySelector("#filter-text-box").value = search;
      setFilterQuery(search);
      setTimeout(() => {
        api.paginationGoToPage(Number(pageToBeReserved));
        localStorage.removeItem('pageToBeReserved')
      }, 1000);
    }
  }

  const onFilterTextBoxChanged = useCallback(() => {
    setFilterQuery(document.getElementById('filter-text-box').value)
  }, []);

  const reloadGridAndMaintainPageNumber = () => {
    fetchRows();
    setTimeout(() => {
      gridApi.paginationGoToPage(currentPage);
    }, 1000);
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={actionWhileLoading}
    >
      <div>
        <div className="Pending-ListHead">
          <h1>Listing "applynow.financethat.ca" Applications</h1>
        </div>
        <div className="ag-theme-alpine ag-theme-quartz" style={{ height: 400, width: '100%' }}>
          <div className="Altable-Container">
            <div className="AlContainer" style={{ padding: "20px 0px 0px" }}>
              <div className="ActiveList-Left pl-3">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onInput={onFilterTextBoxChanged}
                />
              </div>
            </div>
            <div className="dealer-dtable table-responsive applyNowList">

              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                cacheBlockSize={10}
                rowHeight={55}
                domLayout="autoHeight"
                rowModelType={'infinite'}
                pagination={true}
                paginationPageSize={10}
                defaultColDef={{ flex: 1 }}
                onFirstDataRendered={onFirstDataRendered}
              >
              </AgGridReact>

            </div>
          </div>
        </div>

        <PushToFrontlineModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            reloadGridAndMaintainPageNumber(currentPage)
          }}
          applicationId={applicationId}
          modalMessage={modalMessage}
          isApplicationPushed={isApplicationPushed}
        />

      </div>
    </LoadingOverlay>
  );
}

export default ApplynowApplications;